<template>

  <div>
    <CampusHeader
        :header-text="'Exports'"
        :manage-campuses="false"
        :show-campus="false"
        :can-create="false"
        :can-select="false"
        :activeTab="0"
        :header_tabs="[{title: 'Exports'}]"
    />
    <div class="section pt-4">
      <div class="columns">
        <div class="column box is-one-fifth">
          <div class="has-background-white" style="min-height: 100vh">
            <b-menu class="exports-menu">
              <b-menu-list>
                <template v-for="(exportList,index) in exports">
                  <b-menu-item v-if="hasPermissions(exportList.permissions)" :key="index"
                               :expanded="activeList === exportList.title">
                    <template #label="props">
                      <span class="title  is-size-5">{{ exportList.title }}</span>
                      <b-icon class="is-pulled-right"
                              :icon="props.expanded ? 'menu-down' : 'menu-up'"></b-icon>
                    </template>
                    <template v-for="(item,index) in exportList.exports"
                    >
                      <b-menu-item v-if="hasPermission(item.permission)" class="export-link" tag="router-link"
                                   :to="item.route"
                                   :key="index + 'export'"
                      >
                        <template #label>
                          <a class="has-text-primary py-0 is-size-5"> {{ item.title }}</a>
                        </template>
                      </b-menu-item>
                    </template>
                  </b-menu-item>
                </template>

              </b-menu-list>
            </b-menu>
          </div>
        </div>
        <div class="column is-four-fifths ">
          <router-view/>
        </div>

      </div>

    </div>
  </div>
  <!--  <div id="reports">-->
  <!--    <div class="columns">-->
  <!--      <div class="column is-one-fifth reports-list has-background-white">-->
  <!--        <hr/>-->
  <!--        <b-collapse-->
  <!--            class="card"-->
  <!--            v-for="(collapse, index) of collapses"-->
  <!--            :key="index"-->
  <!--            :open="isOpen == index"-->
  <!--            @open="isOpen = index"-->
  <!--        >-->
  <!--          <div slot="trigger" slot-scope="props" class="card-header" role="button">-->
  <!--            <p class="card-header-title">{{ collapse.title }}</p>-->
  <!--            <a class="card-header-icon">-->
  <!--              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>-->
  <!--            </a>-->
  <!--          </div>-->
  <!--          <div-->
  <!--              v-show="hasPermission(list.permission)"-->
  <!--              v-for="list in collapse.lists"-->
  <!--              :key="list.title"-->
  <!--              class="card-content"-->
  <!--          >-->
  <!--            <div class="content">-->
  <!--              <a @click="$router.push('/exports/'+list.to)">{{ list.title }}</a>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </b-collapse>-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->
</template>


<script>

import CampusHeader from "@/components/panelled-dash/CampusHeader";

export default {
  components: {
    CampusHeader
  },
  data() {
    return {
      activeList: 'Enrolments',
      exports: [{
        title: 'Enrolments',
        permissions: ['view enrolments export'],
        exports: [{title: 'Enrolments', route: '/exports/enrolments', permission: 'view enrolments export'}]
      }, {
        title: 'Applications',
        permissions: ['view applications export'],

        exports: [{title: 'Applications', route: '/exports/applications', permission: 'view applications export'}]
      },
        {
          title: 'Attendances',
          permissions: ['view homeclass attendances export', 'view service attendances export'],

          exports: [
            {
              title: 'HomeClasses',
              route: '/exports/attendances-homeclasses',
              permission: 'view homeclass attendances export'
            },
            {
              title: 'Services',
              route: '/exports/attendances-services',
              permission: 'view service attendances export'
            }]
        }, {
          title: 'Users',
          permissions: ['view users export'],

          exports: [
              {
            title: 'Users',
            route: '/exports/users',
            permission: 'view users export'
          },         {
            title: 'Staff',
            route: '/exports/staff',
            permission: 'view staff export'

          },
            {
              title: 'Covid Assessments',
              route: '/exports/covid-assessments',
              permission: 'view covid assessments export'
            },]
        }, {
          title: 'Reports',
          permissions: ['view marksheets export'],

          exports: [
            //     {
            //   title: 'Reports',
            //   route: '/exports/reports',
            //   permission: 'view reports export'
            // },
            {
              title: 'Marksheet',
              route: '/exports/marksheets',
              permission: 'view marksheets export'
            },
          ]
        },
        {
          title: 'Finance',
          permissions: ['view packages export'],

          exports: [{
            title: 'Packages',
            route: '/exports/packages',
            permission: 'view packages export'
          },]
        },
        {
          title: 'Sports Houses',
          permissions: ['view sportshouses export'],

          exports: [{
            title: 'House Report',
            route: '/exports/sports-houses',
            permission: 'view sportshouses export'
          },]
        },
        {
          title: 'Leave',
          permissions: ['view leave requests export'],

          exports: [{
            title: 'Leave Report',
            route: '/exports/leave',
            permission: 'view leave requests export'
          },]
        },

      ],
    };
  },
  computed: {},
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/user-permissions/find"](permission);
    },
    hasPermissions(permissions) {
      return permissions.some(permission => {
        return this.hasPermission(permission)
      })
    },
  },
};
</script>

<style lang="scss">
.min-height-classlist {
  min-height: 100vh;
}

.reports-list {
  .card:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.exports-view {
  max-width: 80%;
}

#reports {
  max-width: 85vw;
}

canvas {
  height: 500px !important;
}
</style>
